import Sortable from 'sortablejs';

// properties
function bootstrap_select_search() {
  $(".select_with_search").selectpicker({
    liveSearch: true,
    size: 10,
    actionsBox: true
  });
  $(".bootstrap_select").selectpicker({
    size: 10
  });
}

$(document).on("turbolinks:load", function () {
  const modal_holder_selector = "#modal-holder";
  const modal_selector = ".modal";

  var el = document.getElementsByClassName('modifier-sortable');
  if (el.length) {
    var sortable = new Sortable(el[0], {
      handle: '.sort-handle',
      onEnd: function (evt) {
        reset_positions();
      }
    });
  }

  $("#item_modifier_sets").on("cocoon:after-insert", function (e, added_row) {
    bootstrap_select_search();
    add_new_modifier();
    added_row.find('.position').val($('.nested-position-fields:visible').length);
  });

  $("#sub_category_modifier_sets").on("cocoon:after-insert", function (e, added_row) {
    bootstrap_select_search();
    add_new_sub_category_modifier();
    added_row.find('.position').val($('.nested-position-fields:visible').length);
  });

  $('#modifiers, #item_modifier_sets, #floor_menu_categories').on('cocoon:after-remove', function (e, insertedItem, originalEvent) {
    reset_positions();
  });

  $('#modifiers, #sub_category_modifier_sets, #floor_menu_categories').on('cocoon:after-remove', function (e, insertedItem, originalEvent) {
    reset_positions();
  });

  $("#item_ingredients").on("cocoon:after-insert", function (e, added_row) {
    bootstrap_select_search();
    add_new_ingredient();
  });

  $(".item_modifier_set").on("change", function () {
    add_new_modifier();
  });

  $(".sub_category_modifier_set").on("change", function () {
    add_new_sub_category_modifier();
  });

  $(".add_item_ingredient").on("change", function () {
    console.log("funs");
    add_new_ingredient();
  });

  function add_new_modifier() {
    $(".item_modifier_set").on("change", function () {
      if ($(this).val() == "create") {
        var el = $(this).attr("id");
        open_remote_model(
          "/products/modifier_sets/new_popup?resp_type=dropdown&el=" + el
        );
      }
    });
  }

  function add_new_sub_category_modifier() {
    $(".sub_category_modifier_set").on("change", function () {
      if ($(this).val() == "create") {
        var el = $(this).attr("id");
        open_remote_model(
          "/products/modifier_sets/new_popup?resp_type=dropdown&el=" + el
        );
      }
    });
  }

  function add_new_ingredient() {
    $(".add_item_ingredient").on("change", function () {
      if ($(this).val() == "create") {
        var el = $(this).attr("id");
        open_remote_model(
          "/products/ingredients/new_popup?resp_type=dropdown&el=" + el
        );
      }
    });
  }

  function open_remote_model(location) {
    const modal_holder_selector = "#modal-holder";
    const modal_selector = ".modal";
    $.get(location, data => {
      $(modal_holder_selector)
        .html(data)
        .find(modal_selector)
        .modal("show");
    });
  }

  function reset_positions() {
    // only consider visible ones
    $('.nested-position-fields:visible').each(function (index) {
      $(this).children().find('.position').val(index + 1);
    });
  }

  $(document).on('shown.bs.modal', function () {
    var el = document.getElementsByClassName('item-modifier-sortable');
    if (el.length) {
      var sortable = new Sortable(el[0], {
        handle: '.sort-handle',
        onEnd: function (evt) {
          $('.nested-position-fields-items:visible').each(function (index) {
            $(this).children().find('.position').val(index + 1);
          });
        }
      });
    }
  });
  

  $(document).on("ajax:success", "form[data-modal]", function (event) {
    const [data, _status, xhr] = event.detail;
    console.log(data);
    if (data.resp_type != "dropdown") {
      // Redirect to url
      window.location = "";
    } else {
      el = "#" + data.el;
      console.log(el);
      $(el).append(new Option(data.name, data.id, false, true));
      $(el).selectpicker("refresh");
      $(modal_selector).modal("hide");
    }

    return false;
  });
});
